#span {
  font-size: 1.1em;
  letter-spacing: 0px;
}
#mentor {
  color: #ffffff;

  letter-spacing: 3px;
  text-shadow: 1px 1px 1px black;
  font-weight: bold;
  font-size: 2.6em;
  text-align: center;
  /*  padding-top: 70px; */
  padding-bottom: 15px;
}
#btns-primary {
  background-color: #003cffcc;

  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0);
}
#help {
  color: #db9847;
  font-size: 1.1em;
  text-align: center;
  /* padding-top: 10px;
   */
  padding-bottom: 15px;
}

.cont-mentor {
  /*background: yellow;*/
  height: 100vh;
  display: grid;
  align-content: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.719);
}
a.text-button-mentor {
  text-decoration: none;
  font-size: 10px;
  color: rgb(69, 5, 141);
}
